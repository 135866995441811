<template>
	<b-container v-if="$checkPermission('view-campaign')" fluid id="campaigns">
		<b-alert id="notification" :show="tooFewSpecificTemplates && tabIndex==0" variant="warning" dismissible><span>{{ $t('Campaigns.TooFewSpecificTemplates') }}</span></b-alert>
		<b-alert id="notification" :show="tooManySpecificTemplates && tabIndex==0" variant="warning" dismissible><span>{{ $t('Campaigns.TooManySpecificTemplates') }}</span></b-alert>
		<cg-template-modal :template="templatePreview" :companyData="companyData" :isCampaign="true" :searchText="searchText" ref="cgTemplateModal"></cg-template-modal>
		<cg-loader :display="inProgress"></cg-loader>
		
		<!-- Campaign Test Target import modal -->
		<b-modal id="test-campaign-modal" size="xl" hide-footer :title="$t('Campaigns.TestCampaign')" @hide="cleanNewTargetForm">
			<cg-localized-alert ref="cgLocTestAlert" :alertVariant="alertVariant" :alertMessage="alertMessage" :alertCount="alertCount" fade="true"></cg-localized-alert>
			<p>{{ $t('Campaigns.TestCampaignInfo') }}</p>

			<b-form @submit.prevent="addTargetToTestCampaign">
				<table class="table mt-2 mb-0">
					<tbody>
						<b-form-group class="mb-0">
							<b-row>
								<b-col class="mb-2" v-for="(field, index) in fields" :key="index">
									<!-- Text widget -->
									<label v-if="textTypes.includes(field.type) && field.display" class="sr-only" :for="field.id">{{ $t(''+field.label) }}</label>
									<b-form-input v-if="textTypes.includes(field.type) && field.display" :id="field.id" :disabled="field.disabled"
										:required="field.required" :autofocus="index==0" autocomplete="new-password" :type="field.type"
										v-model="newTestTarget[field.id]" :placeholder="$t(field.placeholder)" @change="validate($event, field)"
										:class="{'form-field-error': field.error}">
									</b-form-input>
									<b-tooltip target="email" v-if="field.error && field.id == 'email' && !$checkPermission('list-company-all')">{{ $tc('General.InvalidDomain', validDomain.count, {'param': validDomain.domain}) }}</b-tooltip>

									<!-- Select widget -->
									<b-form-select v-if="field.type=='select'" :id="field.id" :disabled="field.disabled" :type="field.type"
										:required="field.required" v-model="newTestTarget[field.id]" :options="field.options"
										:placeholder="$t(field.placeholder)" :class="{'form-field-error': field.error}">
										<template #first>
											<b-form-select-option :value="null" disabled>{{ $t('Template.SelectLanguage') }}</b-form-select-option>
										</template>
									</b-form-select>
								</b-col>

								<b-col>
									<b-button type="submit" :disabled="!formValid" variant="primary">{{ newTestTarget.index > -1 ?  $t('General.Edit') : $t('Targets.Add') }}
									</b-button>
								</b-col>
							</b-row>
						</b-form-group>
					</tbody>
				</table>
			</b-form>

			<b-table class="table table-striped table-bordered table-import mt-2"
				:fields="testTargetFields"
				:items="testTargetList"
				ref="campaignTestTargetTable"
				hover striped show-empty bordered no-sort-reset
				:empty-text="$t('Campaigns.NoTestTarget')">

				<!-- Additional table template info for action column -->
				<template v-slot:cell(ID)="row">
					<div class="table-row-center">
						{{ row.index }}
					</div>
				</template>

				<template v-slot:cell(language)="row">
					{{ localizeLanguage(row.item.language) }}
				</template>

				<template v-slot:cell(actions)="row">
					<div class="table-row-center">
						<div class="action edit">
							<font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
								@click="deleteTestTarget(row.index)"/>
						</div>
						<div class="action delete">
							<font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
								@click="editTestTarget(row.index)"/>
						</div>
					</div>
				</template>
			</b-table>

			<b-row align-h="end" class="cg-modal-footer mt-4 pt-3 pb-1">
				<b-col cols="auto">
					<b-button @click="hideTestCampaignModal" class="mr-2">{{ $t('General.Cancel') }}</b-button>
					<b-button @click="testCampaign" :disabled="!testTargetList.length || inProgress" variant="primary">{{ $t('Campaigns.Test') }}</b-button>
				</b-col>
			</b-row>
		</b-modal>

		<b-card no-body class="mb-4">

			<!-- Alert message -->
			<cg-localized-alert ref="cgLocAlert" :alertVariant="alertVariant" :alertMessage="alertMessage" :alertParams="alertParams" :dismissSecs="alertDismissSecs" fade="true"></cg-localized-alert>

			<!-- Confirm Delete PendingApproval Campaign Modal for Admin only-->
			<b-modal id="confirm-delete-campaign" content-class="shadow" size='md' buttonSize='md' okVariant='primary' 
				bodyClass="text-center" footerClass='p-2' :centered='true' :title="$t('Campaigns.ConfirmDeleteTitle')" @ok="deleteCampaign">

				<p class="my-2 text-left" v-html="$t('Campaigns.ConfirmDeleteMessage')"></p>
			</b-modal>

			<!-- Confirm Delete Complete Campaign Modal for Admin only-->
			<b-modal id="confirm-delete-completed-campaign" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
				bodyClass="text-center" @show="userHasConfirmed = ''" footerClass='p-2' :centered='true' 
				:okDisabled="userHasConfirmed != 'OK'" :title="$t('Campaigns.ConfirmDeleteTitle')" @ok="deleteCampaign">
				
				<p class="my-2 text-left" v-html="$t('Campaigns.ConfirmDeleteCompletedMessage')"></p>
				<p class="my-2 text-left" v-html="$t('General.ConfirmNotReversible')"></p>
				<input class="text-center mt-4" v-model="userHasConfirmed" />
			</b-modal>

			<!-- Confirm complete Campaign Modal for Admin only-->
			<b-modal id="confirm-complete-campaign" content-class="shadow" size='md' buttonSize='md' okVariant='primary' 
				bodyClass="text-center" footerClass='p-2' :centered='true' :title="$t('Campaigns.ConfirmDeleteTitle')" @ok="completeCampaign">
				
				<p class="my-2 text-left" v-html="$t('Campaigns.ConfirmCompleteCampaignMessage')"></p>
			</b-modal>

			<!-- Campaign status tabs: Planned, Approved, Running -->
			<b-tabs card v-model="tabIndex" @activate-tab="changeTab">
				<b-tab no-body v-for="(tab, index) in tabs" v-bind:key="index" :title="tabTitle(tab, index)" @click="notified($event, index)">
					<div class="mx-4 my-4">
						
						<!-- Campaign info header -->
						<b-row class="px-3" v-if="tab.campaign.campaign_id" align-v="start">
							
							<b-col class="margin-bottom-vertical-sm">
								<b-row>
									<!-- Campaign name -->
									<b-col cols="auto" class="campaign-summary margin-bottom-vertical-md">
										<h5 class="my-2">{{ $t('Campaigns.CampaignID', {param: tab.campaign.name}) }}</h5>
										<b-card-text>{{ $t('Campaigns.Template', {param: totalTemplates}) 
											+ " (" + globalTemplates.length + " " + $t('Template.Global') 
											+ " / " + specificTemplates.length + " " + $t('Template.Company') + ")"}}
										</b-card-text>
										<b-card-text class="mb-1 font-14">{{ $t('Campaigns.EmployeeLanguage') }}</b-card-text>
									</b-col>

									<!-- Campaign duration -->
									<b-col class="margin-bottom-vertical-md mt-2">
										<b-card-text class="text-center">{{ $t('Campaigns.CampaignDuration') }}
											<font-awesome-icon v-if="canEditDuration" :class="{ 'pointer': true, 'ml-2': true, 'color-primary': true, 'pencil-edit': editDuration }" :icon="['fas', 'pencil-alt']" @click="toggleEditCampaignDates()"/>
										</b-card-text>

										<div class="date-widget mt-4">
											<div class="timeline left"></div><div class="timeline center"></div><div class="timeline right"></div>
											<div class="day first"></div>
											<div class="day last"></div>
											
											<b-modal id="reset-modal-date" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
												bodyClass="text-center" footerClass='p-2' :centered='true' @ok="reset()" :title="$t('General.UnsavedInfo')">
												<p class="my-2 text-left">{{ $t("General.UnsavedInfoText") }}</p>
											</b-modal>

											<b-form-group class="mb-0" v-if="canEditDuration && editDuration">
												<b-row>
													<b-col>
														<b-form-group class="form-align-self-center mb-0" label-size="sm" label-cols="12" label-align="left" :label="$t('Campaigns.StartDateLabel')" label-for="plannedStartDate">
															<b-form-datepicker 
																id="plannedStartDate"
																:key="campaignDatesKey"
																:class="{ 'campaign-date campaign-date-edit': canEditDuration && editDuration, 'campaign-date': !canEditDuration || !editDuration, 'form-field-error': invalidField.includes('plannedStartDate') }"
																:date-format-options="{ 'year': 'numeric', 'month': '2-digit', 'day': '2-digit' }"
																v-model="plannedStart.date" 
																:disabled="!canEditDuration || !editDuration"
																:locale="$i18n.locale">
															</b-form-datepicker>
														</b-form-group>
													</b-col>
													<b-col>
														<b-form-group class="form-align-self-center mb-0" label-size="sm" label-cols="12" label-align="left" :label="$t('Campaigns.CampaignDurationLabel')" label-for="campaignDuration">
															<b-form-input 
																id="campaignDuration" 
																:key="campaignDatesKey"
																:class="{ 'placeholder-bold': true, 'form-field-error': invalidField.includes('campaignDuration') }"
																:disabled="!canEditDuration || !editDuration"
																v-model="deadline.campaign"/>
														</b-form-group>
														
													</b-col>
												</b-row>
												<b-row>
													<b-col>
														<b-form-group class="form-align-self-center" label-size="sm" label-cols="12" label-align="left" :label="$t('Campaigns.StartTimeLabel')" label-for="plannedStartTime">
															<b-form-timepicker
																id="plannedStartTime"
																:key="campaignDatesKey"
																:class="{ 'campaign-time': true, 'form-field-error': invalidField.includes('plannedStartTime') }"
																v-model="plannedStart.time" 
																:locale="$i18n.locale"
																:disabled="!canEditDuration || !editDuration">
															</b-form-timepicker>
														</b-form-group>
													</b-col>
													<b-col>
														<b-form-group class="form-align-self-center" label-size="sm" label-cols="12" label-align="left" :label="$t('Campaigns.SendEmailsByLabel')" label-for="sendEmailsBy">
															<b-form-input 
																id="sendEmailsBy" 
																:key="campaignDatesKey"
																:class="{ 'placeholder-bold': true, 'form-field-error': invalidField.includes('sendEmailsBy') }"
																:disabled="!canEditDuration || !editDuration"
																v-model="deadline.email"/>
														</b-form-group>
													</b-col>
												</b-row>
												<b-button @click="updateCampaignDates" variant="primary" size="sm" class="mt-2 float-right">{{ $t('Campaigns.UpdateDurations') }}</b-button>
												<b-button @click="resetCampaignDates" variant="secondary" size="sm" class="mt-2 float-right mr-2">{{ $t('General.Cancel') }}</b-button>
											</b-form-group>

											<div v-else>
												<div class="campaign-date-static">
													<b>{{plannedStart.date | campaignDate($account.timezone)}}</b>
												</div>
												<div class="campaign-date-static">
													<b>{{plannedEnd.date | campaignDate($account.timezone)}}</b>
												</div>
											</div>
										</div>
									</b-col>
								</b-row>
							</b-col>

							<!-- Campaign modal before approve -->
							<b-modal id="approve-campaign-confirm" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
								bodyClass="text-center"  footerClass='p-2' :centered='true' :title="$t('Account.ConfirmDeleteTitle')" @ok="activateCampaign">
								<p class="my-2 text-left">{{ $t('Campaigns.ConfirmCampaignTitle') }}</p>
							</b-modal>

							<b-modal id="remake-campaign-confirm" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
								bodyClass="text-center" footerClass='p-2' :centered='true' :title="$t('Account.ConfirmDeleteTitle')" @ok="remakeCampaign">
								<p class="my-2 text-left">{{ $t('Campaigns.ConfirmRemakeCampaign') }}</p>
							</b-modal>

							<b-col cols="auto" class="campaign-action-container" v-show="$checkPermission('approve-campaign') || $checkPermission('delete-campaign')">
								<span class="campaign-action-title">{{ $t('Campaigns.Actions') }}</span>

								<!-- Campaign approval button -->
								<b-container v-if="index == 0">
									<b-button variant="primary" class="btn-action mb-2" :disabled="tooFewTemplates || !canApprove" v-if="tab.campaignTemplates.length > 0 && $checkPermission('approve-campaign')"
										@click="$bvModal.show('approve-campaign-confirm')">{{ $t('Campaigns.Approve') }}</b-button>
									<b-button variant="primary" class="btn-action" block v-if="tab.campaignTemplates.length > 0 && $checkPermission('remake-campaign')"
										@click="$bvModal.show('remake-campaign-confirm')">{{ $t('Campaigns.Remake') }}</b-button>
									<b-button variant="link" class="underlined-button" block v-if="$checkPermission('delete-campaign')"
										@click="confirmDeleteCampaign(null)">{{ $t('General.Delete') }}</b-button>
								</b-container>

								<!-- Campaign unapprove after approve -->
								<b-container v-if="index == 1 && $checkPermission('approve-campaign')">
									<b-button variant="primary" class="btn-action" :disabled="!isOnboardingCompleted" v-if="tab.campaignTemplates.length > 0"
										@click="$bvModal.show('disapprove-campaign-confirm')">{{ $t('Campaigns.Unapprove') }}</b-button>
								</b-container>

								<!--  Campaign resume/suspend --> 
								<b-container v-if="index == 2" class="d-flex flex-column align-items-center">
									<b-button variant="primary" class="btn-action mb-2" v-if="tab.campaign.suspended && $checkPermission('delete-campaign')"
										@click="changeCampaignSuspendStatus(false)">{{ $t('Campaigns.Resume') }}</b-button>
									<b-button variant="primary" class="btn-action mb-2" v-else-if="$checkPermission('delete-campaign')"
										@click="changeCampaignSuspendStatus(true)">{{ $t('Campaigns.Suspend') }}</b-button>
									<b-button variant="primary" class="btn-action" v-if="$checkPermission('list-company-all')"
										@click="confirmCompleteCampaign">{{ $t('Campaigns.CompleteCampaign') }}</b-button>
								</b-container>
							</b-col>
							
							<!-- Campaign modal unapprove after approve -->
							<b-modal id="disapprove-campaign-confirm" content-class="shadow" size='md' buttonSize='md' okVariant='danger' 
								bodyClass="text-center" footerClass='p-2' :centered='true' :title="$t('Account.ConfirmDeleteTitle')" @ok="disapproveCampaign">
								<p class="my-2 text-left">{{ $t('Campaigns.ConfirmUnapproveMessage') }}</p>
							</b-modal>

						</b-row>

						<!-- Test Campaign -->
						<div class="mt-3 mt-4 pt-4 border-top" v-if="tab.campaign.campaign_id && index == 0">
							<b-row align-h="end">
								<b-col class="text-right font-14 line-height-30">{{ $t('Campaigns.TestCampaignLabel') }}</b-col>
								<b-col align-h="end" class="col-auto">
									<b-button variant="primary" size="sm" class="w-100" @click="showTestCampaignModal">{{ $t("Campaigns.TestCampaign") }}</b-button>
								</b-col>
							</b-row>
						</div>

						<!-- Campaign info header, no Campaign available -->
						<b-row class="px-3 text-center line-height-200" v-if="!tab.campaign.campaign_id">
							<b-col>
								<b-card-text class="mb-1">{{ $t('Campaigns.NoAvailableCampaign') }}</b-card-text>
							</b-col>
						</b-row>

						<!-- Campaign Templates data -->
						<div class="mt-3 mt-4 pt-4 px-3 border-top" v-if="tab.campaign.campaign_id">

							<!-- Template Action Panel -->
							<b-row>
								<!-- Change Template Card layout -->
								<b-col sm="12" md="4" lg="6" class="mb-2">
									<b-input-group size="sm" class="mb-2 template-grid">
										<template v-slot:prepend>
											<b-input-group-text class="icon-append">
												<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'th']"/>
											</b-input-group-text>
										</template>
										<b-form-spinbutton v-model="cardPerRow" min="1" max="5" step="1" inline class="spinbutton"></b-form-spinbutton>
									</b-input-group>
								</b-col>

								<!-- Template Search and Filter -->
								<b-col>
									<b-form-group class="form-align-self-right">
										<b-input-group size="sm">
											<label class="sr-only" for="templateCountryFilter">{{ $t('General.Filter') }}</label>
											<b-form-select class="left-border-radius mr-3"
												v-if="tabIndex==0"
												id="templateCountryFilter"
												size="sm"
												v-model="templateCountryFilter"
												:options="templateCountryOptions"
											></b-form-select>
											<label class="sr-only" for="search-template">{{ $t('General.Search') }}</label>
											<b-form-input v-model="searchText" type="search" id="search-template" class="left-border-radius"
												:placeholder="$t('General.TypeToSearch')"
												:aria-label="$t('General.TypeToSearch')"
												@input="searchTemplates(tab.campaign.campaign_id, index)"
											></b-form-input>
											<b-input-group-append>
												<b-input-group-text class="icon-append search" :class="{'mr-3': tabIndex == 0}">
													<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'search']"/>
												</b-input-group-text>
											</b-input-group-append>
											<label class="sr-only" for="templateStatusFilter">{{ $t('General.Filter') }}</label>
											<b-form-select class="left-border-radius"
												v-if="tabIndex==0"
												id="templateStatusFilter"
												size="sm"
												v-model="templateStatusFilter"
												:options="templateStatusOption"
											></b-form-select>
										</b-input-group>
									</b-form-group>
								</b-col>
							</b-row>

							<!-- Template Counter -->
							<b-row v-if="index == 0 && showFeedback">
								<span class="selected-template counter ml-3" :class="{ 'text-danger font-weight-bold' : tooFewTemplates }">
									{{ $t('Campaigns.SelectedTemplates', {param: selectedTemplates.count, param1: filteredTemplates.length}) }}
								</span>
								<span class="selected-template ml-4" v-if="tooFewCountryTemplates">
									<font-awesome-icon class="vertical-align-middle" :icon="['fas', 'exclamation-triangle']"/>
									{{ $t('Campaigns.TooFewTemplatesSelected', {country: selectedTemplates.error}) }}
								</span>
							</b-row>

							<b-row v-if="index == 0">
								<span class="selected-template ml-3 text-danger font-weight-bold" v-if="tooFewTemplates">
									{{ $t('Campaigns.TooFewTemplates', {param: minTemplateAllowed}) }}
								</span>
							</b-row>

							<!-- Template Card list -->
							<b-row>
								<div class="mt-3 w-100">
									<b-card-group v-if="filteredTemplates.length > 0">
										<cg-template-card
											v-for="(template, index) in filteredTemplates" 
											:cardPerRow="cardPerRow"
											:template="template"
											:tabIndex="tabIndex"
											:companyData="companyData"
											:index="index"
											:campaignId="tab.campaign.campaign_id"
											:searchText="searchText"  
											:key="template.template_id"
											@preview="showPreviewModal"
										></cg-template-card>
									</b-card-group>
									<div v-else class="text-center line-height-200">{{ $t('General.TableFilterEmpty') }}</div>
								</div>
							</b-row>
						</div>

					</div>

					<b-card-footer v-if="supportMessage" v-html="supportMessage"></b-card-footer>
				</b-tab>
				<!-- Completed Campaign list, if any -->
				<b-tab no-body v-if="$checkPermission('list-company-all')" :title="$t('Campaigns.Completed')">

					<div class="mx-4 my-4">
						
						<!-- Campaign info header -->
						<b-row class="mx-3" align-v="start">
							
							<b-col class="margin-bottom-vertical-sm">
								<b-row>
									<b-col cols="auto" class="campaign-summary margin-bottom-vertical-md">
										<h5 class="my-2">{{ $t('Campaigns.CompletedCampaigns') }}</h5>
										<b-card-text class="mb-4">{{ $t('Campaigns.CompletedCampaignSubtitle') }}
										</b-card-text>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
	
						<b-table
							class="table table-striped table-bordered table-import mt-2 mx-3"
							ref="completedCampaignList"
							:fields="campaignFields"
							:items="getCompletedCampaign"
							hover striped show-empty bordered no-sort-reset
							:empty-text="$t('General.TableEmpty')">

							<!-- Additional table template info for action column -->
							<template v-slot:cell(actions)="row">
								<div class="table-row-center">
									<div class="action delete" v-if="row.index === 0">
										<font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
											@click="confirmDeleteCampaign(row.item.campaign_id)"/>
									</div>
								</div>
							</template>
						</b-table>
					</div>
					<b-card-footer v-if="supportMessage" v-html="supportMessage"></b-card-footer>
				</b-tab>
			</b-tabs>
		</b-card>

	</b-container>
</template>

<script>
import campaignService from "../services/campaign.service.js";
import templateService from "../services/template.service";
import companyService from "../services/company.service.js";
import syspropService from "../services/sysprop.service.js";
import { Campaign, TemplateType, TemplateScope } from "../../../server/common/constants";
import Vue from 'vue';
import { isEmail } from 'validator';

export default {
	name: "cg-campaigns",
	data: () => {
		return {
			tabs: [
				{
					title: "Campaigns.Planned",
					campaignTitle: "Campaigns.PlannedCampaign",
					campaign: {},
					campaignTemplates: [],
					maxTemplateAvailable: 0,
				},
				{
					title: "Campaigns.Approved",
					campaignTitle: "Campaigns.ApprovedCampaign",
					campaign: {},
					campaignTemplates: [],
					maxTemplateAvailable: 0,
				},
				{
					title: "Campaigns.Active",
					campaignTitle: "Campaigns.ActiveCampaign",
					campaign: {},
					campaignTemplates: [],
					maxTemplateAvailable: 0,
				}
			],
			fields: [
				{   id: "firstname",
					label: 'Targets.FirstName',
					type: "text",
					display: true,
					required: true,
					placeholder: 'Targets.FirstName'
				},
				{   id: "lastname",
					label: 'Targets.LastName',
					type: "text",
					display: true,
					required: false,
					placeholder: 'Targets.LastName'
				},
				{   id: "email",
					label: 'Targets.Email',
					type: "email",
					display: true,
					required: true,
					placeholder: 'Targets.Email'
				},
				{   id: "language",
					label: 'Targets.Language',
					type: "select",
					display: true,
					required: true,
					options: [],
					placeholder: 'Targets.Language'
				},
				{   id: "telephone",
					label: 'Targets.Telephone',
					type: "text",
					display: true,
					required: false,
					placeholder: 'Targets.Telephone'
				}
			],
			formValid: true,
			inProgress: false,
			testTargetList: [],
			testTargetFields: [],
			newTestTarget: {
				firstname: null,
				lastname: null,
				email: null,
				telephone: null,
				language: null
			},
			cardPerRow: 3,
			templatePreview: null,
			templateShown: null,
			textTypes: ['text', 'password', 'email', 'number', 'search', 'url', 'tel', 'date', 'time', 'range', 'color'],
			companyId: localStorage.getItem("cg-company"),
			preview: "",
			alertMessage: "",
			alertCount: 0,
			alertVariant: "success",
			alertParams: {},
			searchText: "",
			tabIndex: 0,
			searchingTemplates: false,
			previewLanguage: "",
			minTemplateAllowed: null,
			emailSubject: null,
			emailSender: null,
			templateStatusFilter: null,
			templateCountryFilter: null,
			templateStatusOption: [],
			templateCountryOptions: [],
			companyData: {},
			supportMessage: null,
			editDuration: false,
			isOnboardingCompleted: true,
			invalidField: [],
			canApprove: null,
			campaignDatesKey: 0,
			emailDomain: null,
			validDomain: {},
			invalidDomainTargetCount: 0,
			campaignFields: [],
			campaignToDelete: null,
			userHasConfirmed: ''
		}
	},
	computed: {
		tooFewTemplates() {
			return this.tabs[0].campaignTemplates ? this.selectedTemplates.global < this.minTemplateAllowed : false;
		},
		tooFewCountryTemplates() {
			return this.tabs[0].campaignTemplates ? this.selectedTemplates.error !== "" : false;
		},
		showFeedback() {
			return !this.searchingTemplates && this.searchText.length == 0;
		},
		selectedTemplates() {
			let temp = 0;
			let nullCountry = 0; //selected templates whitout a specific country (country = NULL)
			let global = 0; //all selected templates, unrelated to a specific country
			let countryTemp = []; //selected templates for a specific country 

			// Create temporary data to count selected templates for each country
			this.templateCountryOptions.forEach((t) => {
				if(t.value != null) {
					countryTemp[t.value] = { val: 0, label: t.text };
				}
			});

			// First count selected templates with any filters applied
			if (this.filteredTemplates) {
				this.filteredTemplates.forEach(template => {
					if (template.status == "1") {
						temp += 1;
					}
				});
			}

			// Then count globally selected templates for each country
			if (this.tabs[0].campaignTemplates) {
				this.tabs[0].campaignTemplates.forEach(template => {
					if (template.status == "1") {
						global += 1;
						if(template.label) {
							template.label.forEach((t) => {
								if(countryTemp[t] && countryTemp[t].val >= 0) {
									countryTemp[t].val += 1;
								}
							});
						} else {
							nullCountry += 1;
						}
					}
				});
			}

			// Manage error in the form of list of countries
			let error = [];
			Object.keys(countryTemp).forEach((t) => {
				countryTemp[t].val += nullCountry;
				if(countryTemp[t].val < this.minTemplateAllowed) {
					error.push(countryTemp[t].label);
				}
			});

			return { global: global, count: temp, error: error.join(', ') };
		},
		filteredTemplates() {
			if(this.tabIndex > 2) return [];
			return this.tabs[this.tabIndex].campaignTemplates.filter((template) => {
				return (this.templateStatusFilter === null || template.status == this.templateStatusFilter) && 
					(this.templateCountryFilter === null || template.label === null || template.label && template.label.includes(this.templateCountryFilter)) });
		},
		alertDismissSecs() {
			// show alert message if there is a campaign for approval, the user has the permission to do it and there are no targets for the company
			if (this.$checkPermission('approve-campaign') && Object.keys(this.tabs[0].campaign).length > 0 && this.canApprove == false) {
				this.$refs.cgLocAlert.showAlert();
				return 60;
			}
			return 5;
		},
		specificTemplates() {
			if(this.tabIndex > 2) return [];
			return this.tabs[this.tabIndex].campaignTemplates.filter((template) => { return template.scope === TemplateScope.Company });
		},
		globalTemplates() {
			if(this.tabIndex > 2) return [];
			return this.tabs[this.tabIndex].campaignTemplates.filter((template) => { return template.scope === TemplateScope.Global });
		},
		totalTemplates() {
			return this.globalTemplates?.length + this.specificTemplates?.length || 0;
		},
		specificTemplatesThreshold() {
			return Math.round(this.companyData.template_specific_assign_rate * this.tabs[0].campaignTemplates.length);
		},
		tooFewSpecificTemplates() {
			return this.specificTemplates ? (this.specificTemplates.length < this.specificTemplatesThreshold) : false;
		},
		tooManySpecificTemplates() {
			return this.specificTemplates ? (this.specificTemplates.length > this.specificTemplatesThreshold) : false;
		},
		plannedStart() {
			if(this.tabIndex > 2) return { date: null, time: null };
			return {
				date: this.$moment.utc(this.tabs[this.tabIndex].campaign.planned_start).local().locale(this.$i18n.locale).format("YYYY-MM-DD"),
				time: this.$moment.utc(this.tabs[this.tabIndex].campaign.planned_start).local().locale(this.$i18n.locale).format("HH:mm:ss") 
			}
        },
		plannedEnd() {
			if(this.tabIndex > 2) return { date: null, time: null };
			return {
				date: this.$moment.utc(this.tabs[this.tabIndex].campaign.planned_end).local().locale(this.$i18n.locale).format("YYYY-MM-DD"),
				time: this.$moment.utc(this.tabs[this.tabIndex].campaign.planned_end).local().locale(this.$i18n.locale).format("HH:mm:ss") 
			}
        },
		canEditDuration() {
			return this.tabIndex == 0;
		},
		deadline() {
			let campaign_duration = this.$moment.duration(this.$moment(this.tabs[this.tabIndex].campaign.planned_end).diff(this.$moment(this.tabs[this.tabIndex].campaign.planned_start)));
			let send_emails_by = this.$moment.duration(this.$moment(this.tabs[this.tabIndex].campaign.send_emails_by).diff(this.$moment(this.tabs[this.tabIndex].campaign.planned_start)));
		
			campaign_duration._data.days = campaign_duration.as('days');
			campaign_duration._data.months = 0;
			send_emails_by._data.days = send_emails_by.as('days');
			send_emails_by._data.months = 0;
			campaign_duration = this.formatDuration(campaign_duration._data);
			send_emails_by = this.formatDuration(send_emails_by._data);

			return {
				campaign: campaign_duration ? campaign_duration : this.companyData.campaign_duration,
				email: send_emails_by ? send_emails_by : this.companyData.send_emails_by
			}
		}
	},
	beforeRouteLeave(to, from, next) {
		if(this.editDuration && this.tabIndex == 0 && !this.isSameDate()) {
			this.to = to;
			this.$bvModal.show('reset-modal-date');
		} else {
			next();
		}
	},
	methods: {
		// Get Company Campaign in status=statusValue 
		getCampaign(statusValue, companyId) {
			return new Promise(function (resolve, reject) {
				var option = {
					status: statusValue
				}
				companyService.getCampaignList(companyId, option).then(async function (result) {
					// Retrieve the associated template if such Campaign does exist
					if (result.data && result.data.length > 0) {
						resolve(result.data);
					} else {
						resolve(null);
					}
				}).catch((err) => {
					reject(err);
				});
			});
		},
		formatDuration(date) {
			let duration = "P" + (date.years? date.years + "Y" : '') + (date.months? date.months + "M" : '') + (date.days? date.days + "D" : '');
			if(date.hours || date.minutes || date.seconds) {
				duration += "T" + (date.hours? date.hours + "H" : '') + (date.minutes? date.minutes + "M" : '') + (date.seconds? date.seconds + "S" : '');
			}
			
			return duration;
		},
		showPreviewModal(record) {
			this.templatePreview = record;
			this.$refs.cgTemplateModal.showPreviewModal(this.templatePreview);
		},
		async getTemplates(companyId, campaignId, templateStatus) {
			console.debug("Campaigns - getTemplates", campaignId);

			try {
				let res = await campaignService.getCampaignTemplates(campaignId, templateStatus);

				return await this.getTemplateContents(res.data); 
			} catch (error) { 
				console.error("Campaigns - getTemplates - ERROR", error);
			}
		},
		async getTemplateContents(fullListTemplates) {
			console.debug("Campaigns - getTemplateContents");

			try {
				// Gather also Template content to effectively render the Cards
				let templatePromiseArray = [];

				// Populate promise array
				fullListTemplates.forEach((element) => {
					templatePromiseArray.push(templateService.getTemplateDetails(element.template_id));
				});

				// Await for all promises to be resolved before proceeding
				const templateContent = await Promise.all(templatePromiseArray);

				let fullBodyTemplates = [];

				// Merge info about template content in Template object for the component
				fullListTemplates.map(x => {
					let tc = templateContent.filter(t => t && t.data[0] && t.data[0].template_id === x.template_id);

					// Template Content may be empty if the template has not been correctly imported
					if(!tc || !tc.length) {
						x.body = [{ body: "", hints: 0 }];
						x.languages = 0;
					} else {
						x.body = tc[0].data;
						x.languages = tc[0].data.length;
					}
					fullBodyTemplates.push(x);
				});

				// Select unique Template
				return [...new Map(fullBodyTemplates.map(item => [item['template_id'], item])).values()];
			} catch (error) { 
				console.error("Campaigns - getTemplateContents - ERROR", error);
			}
		},
		// Get all Company Campaign list
		getAllCampaign() {
			console.debug("Campaigns - getAllCampaign");
			var self = this;
			this.preview = null;

			function getCampaignData(campaignStatus, index, companyId) {
				self.getCampaign(campaignStatus, companyId).then(async function (result) {
					if (result && result.length > 0) {
						self.tabs[index].campaign = result[0];

						// For planned Campaigns (status==1) there is no need 
						// to filter Templates by their status (0 -> excluded, 1 ->included)
						var templateStatus = campaignStatus == 1 ? null : 1;
						self.tabs[index].campaignTemplates = await self.getTemplates(self.companyId, result[0].campaign_id, templateStatus);
						self.tabs[index].maxTemplateAvailable = self.tabs[index].campaignTemplates.length > self.tabs[index].maxTemplateAvailable?
						self.tabs[index].campaignTemplates.length : self.tabs[index].maxTemplateAvailable;
					} else {
						self.tabs[index].campaign = {};
						self.tabs[index].campaignTemplates = [];
						self.tabs[index].maxTemplateAvailable = 0;
					}
				});
			}

			/**
			 * Campaign status list
			 * 1 Campaign awaiting for approval, matched to tab 0
			 * 2,3 Campaign approved or starting, matched to tab 1
			 * 4 Campaign currently running, matched to tab 2
			 */
			getCampaignData(Campaign.Status.PendingApproval, 0, this.companyId);
			getCampaignData([Campaign.Status.Approved, Campaign.Status.Starting], 1, this.companyId);
			getCampaignData(Campaign.Status.Running, 2, this.companyId);
		},
		// Search by Template name and body attribute among displayed Templates
		searchTemplates(campaignId, index) {
			if(!campaignId || (this.searchText.length < 3 && this.searchText != "")) {
				return;
			}
			this.searchingTemplates = true;
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}

			this.searchTimeout = setTimeout(async () => {
				console.debug("Campaigns - searchTemplates", campaignId, index);
				var templateStatus = (this.tabIndex==0) ? null : 1;
				campaignService.searchTemplates(campaignId, this.searchText,templateStatus).then(async (result) => {
					this.tabs[index].campaignTemplates = await this.getTemplateContents(result.data);
				}).catch((error) => {
					console.error(error);
				}).finally(() => {
					this.templateShown = null;
					this.preview = "";
					this.searchingTemplates = false;
				});
			}, 700);
		},
		async activateCampaign() {
			await campaignService.changeCampaignStatus(this.companyId, this.tabs[0].campaign.campaign_id, Campaign.Status.Approved);
			this.tabIndex = 1; // Show just Approved Campaign
			this.getAllCampaign();
		},
		async disapproveCampaign() {
			await campaignService.changeCampaignStatus(this.companyId, this.tabs[1].campaign.campaign_id, Campaign.Status.PendingApproval);
			this.tabIndex = 0; 
			this.getAllCampaign();
		},
		async changeCampaignSuspendStatus(status) {
			this.inProgress = true;

			campaignService.changeCampaignSuspendStatus(this.tabs[2].campaign.campaign_id, status).then((result) => {
				console.debug("Campaign - changeCampaignSuspendStatus success", result);
				this.alertVariant = 'success';
				this.alertMessage = status? 'Campaigns.ChangeCampaignSuspend' : 'Campaigns.ChangeCampaignResume';

				// Refresh table to reload data
				this.$nextTick(() => {
					this.tabs[2].campaign.suspended = status;
				});	
			}).catch((error) => {
				console.error("Campaign - changeCampaignSuspendStatus error", error);
				this.alertVariant = 'danger';
				this.alertMessage = status? 'Campaigns.ChangeCampaignSuspendError' : 'Campaigns.ChangeCampaignResumeError';
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		async completeCampaign() {
			this.inProgress = true;

			campaignService.completeCampaign(this.tabs[2].campaign.campaign_id).then(() => {
				console.debug("Campaign - completeCampaign success");
				this.alertVariant = 'success';
				this.alertMessage = 'Campaigns.CompleteCampaignSuccess';

				// Refresh
				this.$nextTick(() => {
					this.tabs[2].campaign.planned_end = Date.now();
				});
			}).catch((error) => {
				console.error("Campaign - completeCampaign error", error);
				this.alertVariant = 'danger';
				this.alertMessage = 'Campaigns.CompleteCampaignError';
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		confirmDeleteCampaign(campaignId) {
			console.debug("Campaign - confirmDeleteCampaign", campaignId);
			this.campaignToDelete = null;
			
			if(campaignId) {
				this.campaignToDelete = campaignId;
				this.$bvModal.show('confirm-delete-completed-campaign');
			} else {
				this.$bvModal.show('confirm-delete-campaign');
			}
		},
		confirmCompleteCampaign() {
			console.debug("Campaign - confirmCompleteCampaign");

			this.$bvModal.show('confirm-complete-campaign');
		},
		async deleteCampaign() {
			let campaignId = this.campaignToDelete ? this.campaignToDelete : this.tabs[0].campaign.campaign_id;
			this.inProgress = true;

			campaignService.deleteCampaign(campaignId).then(() => {
				console.debug("Campaign - deleteCampaign success");
					
				this.alertVariant = 'success';
				this.alertMessage = 'Campaigns.CampaignDeleted';

				// Refresh table to reload data
				this.$nextTick(() => {
					if(this.campaignToDelete) {
						this.$refs.completedCampaignList.refresh();
					} else {
						this.tabs[0].campaign = {};
					}
				});	
			}).catch((error) => {
				console.error("Campaign - deleteCampaign error", error);
				this.alertVariant = 'danger';
				this.alertMessage = 'Campaigns.CampaignDeleteError';
			}).finally(() => {
				this.inProgress = false;
				this.createAlert(this.alertVariant, this.alertMessage);
			});
		},
		async remakeCampaign() {
			this.inProgress = true;
			campaignService.remakeCampaign(this.tabs[0].campaign.campaign_id, this.companyId).then(() => {
				console.debug("Campaign - remakeCampaign success");
					
				this.alertVariant = 'success';
				this.alertMessage = 'Campaigns.AlertConfirmRemakeCampaign';

				var index = 0;
				this.preview = null;

				this.getCampaign(Campaign.Status.PendingApproval, this.companyId).then(async (result) => {
					if (result && result.length > 0) {
						this.tabs[index].campaign = result[0];

						// For planned Campaigns (status==1) there is no need 
						// to filter Templates by their status (0 -> excluded, 1 ->included)
						var templateStatus = Campaign.Status.PendingApproval == 1 ? null : 1;
						this.tabs[index].campaignTemplates = await this.getTemplates(this.companyId, result[0].campaign_id, templateStatus);
						this.tabs[index].maxTemplateAvailable = this.tabs[index].campaignTemplates.length > this.tabs[index].maxTemplateAvailable?
							this.tabs[index].campaignTemplates.length : this.tabs[index].maxTemplateAvailable;
					} else {
						this.tabs[index].campaign = {};
						this.tabs[index].campaignTemplates = [];
						this.tabs[index].maxTemplateAvailable = 0;
					}
					this.inProgress = false;
					this.$refs.cgLocAlert.showAlert();
				});

			}).catch((error) => {
				console.error("Campaign - remakeCampaign error", error);
				this.alertVariant = 'danger';
				this.alertMessage = 'Campaigns.AlertErrorInRemakeCampaign';
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		},
		cleanSelection() {
			this.searchText = "";
			if(this.tabs[this.tabIndex].campaign && this.tabs[this.tabIndex].campaign.campaign_id) {
				this.searchTemplates(this.tabs[this.tabIndex].campaign.campaign_id, this.tabIndex);
			}
			this.preview = null;
		},
		preventTemplateDeselect(status) {
			// In order to prevent the user to uncheck too many templates, disable toggle status for checked checkbox 
			if (this.tabs[0].campaignTemplates) {
				if (this.selectedTemplates < this.minTemplateAllowed) {
					return status == 1;
				}
			}
			return false;
		},
		tabTitle(tab, index) {
			// Show the notification dot only in Approved or Active tab, if they contains info about a Campaign
			if(tab.campaign.name && !tab.notified && index != 0) {
				return '• ' + this.$t(tab.title);
			} else {
				return this.$t(tab.title);
			}
		},
		reset() {
			this.editDuration = false;
			this.resetCampaignDates();
			if(this.nextTab) {
				this.tabIndex = this.nextTab;
			} else if(this.to) {
				this.$router.push(this.to.path);
			}
		},
		changeTab(nextTab, prevTab, event) {
			if(this.editDuration && prevTab == 0 && !this.isSameDate()) {
				event.preventDefault();
				this.nextTab = nextTab;
				this.$bvModal.show('reset-modal-date');
			}
		},
		notified(event, index) {
			// There is a little dot close to the title of the tab containing info about a Campaign
			// It works as a notification alert, so when the tab is clicked, the dot should disappear
			this.tabs[index].notified = true;
			this.tabTitle(this.tabs[index], index);
		},
		cleanNewTargetForm() {
			this.newTestTarget = {
				firstname: null,
				lastname: null,
				email: null,
				telephone: null,
				language: null
			};
		},
		showTestCampaignModal() {
			this.newTestTarget.language = this.$i18n.locale || null;

			this.$bvModal.show('test-campaign-modal');
		},
		hideTestCampaignModal() {
			this.$bvModal.hide('test-campaign-modal');
		},
		async testCampaign() {
			this.inProgress = true;
			console.debug("Test Campaign with these users", this.testTargetList);
			companyService.updateCompanyTestTargets(this.companyId, this.testTargetList, this.tabs[0].campaign.campaign_id, Campaign.Type.Test).then(() => {
				console.debug("Campaign - updateCompanyTestTargets success");
				this.alertVariant = 'success';
				this.alertMessage = 'Campaigns.UpdateCompanyTestTargets';
			}).catch((error) => {
				console.error("Campaign - updateCompanyTestTargets error", error);
				this.alertVariant = 'danger';
				this.alertMessage = 'Campaigns.UpdateCompanyTestTargetsError';
			}).finally(() => {
				this.inProgress = false;
				this.hideTestCampaignModal();
				this.$refs.cgLocAlert.showAlert();
			});
		},
		addTargetToTestCampaign() {
			var tempList = [...this.testTargetList];
			if (this.newTestTarget.index > -1) {
				tempList[this.newTestTarget.index] = this.newTestTarget;
			} else {
				tempList.push(this.newTestTarget);
			}
			const uniqueValues = new Set(tempList.map(v => v.email));

			if (uniqueValues.size < tempList.length) {
				this.alertVariant = 'danger';
				this.alertMessage = 'Campaigns.DuplicateEmail';
				this.$refs.cgLocTestAlert.showAlert();

				Vue.set(this.fields.find(i => i.id === 'email'), 'error', true);
				this.formValid = false;
				return;
			}

			if(this.newTestTarget.index > -1) {
				Vue.set(this.testTargetList, this.newTestTarget.index, this.newTestTarget);

				// Refresh table to update data
				this.$nextTick(() => {
					this.$refs.campaignTestTargetTable.refresh();
				});
			} else {
				this.testTargetList.push({
					lastname: this.newTestTarget.lastname,
					firstname: this.newTestTarget.firstname,
					email: this.newTestTarget.email,
					telephone: this.newTestTarget.telephone,
					language: this.newTestTarget.language
				});
			}

			this.newTestTarget = {};
		},
		deleteTestTarget(index) {
			this.testTargetList.splice(index, 1);
		},
		editTestTarget(index) {
			this.newTestTarget = Object.assign({}, this.testTargetList[index]);
			this.newTestTarget.index = index;
		},
		isValidTelephoneNumber(number) {
			return (/^(\+)?([0-9]{1,24})$/).test(number) && number.length < 25;
		},
		validate(value, field) {
			field.error = false;
			if(typeof field.validator =='function' && value != '') {
				this.formValid = field.validator(value);
				if(!this.formValid) {
					field.error = true;
				} 
			}
		},
		localizeLanguage(l) {
			let availableLanguages = this.fields.find(i => i.id === 'language');
			return availableLanguages.options[l] || l;
		},
		createAlert(variant, message, alertParams) {
			this.alertVariant = variant;
			this.alertMessage = message;
			this.alertParams = alertParams;
			this.$refs.cgLocAlert.showAlert();
		},
		updateCampaignDates() {
			// reset the invalidField array
			this.invalidField = [];
			clearTimeout(this.debounceUpdateTimer);

			// Set a debounce timer to avoid instant update
			this.debounceUpdateTimer = setTimeout(() => {
				const plannedStartDate = this.plannedStart.date;
				const plannedStartTime = this.plannedStart.time;

				// Retrieve last running campaign planned end date
				//const lastCampaignEnd = this.tabs[2].campaign.planned_end? this.$moment(this.tabs[2].campaign.planned_end).local().locale(this.$i18n.locale).utc() : null;

				// Creates complete dates by adding time values to selected date values
				const plannedStart = this.$moment(plannedStartDate + ' ' + plannedStartTime).local().locale(this.$i18n.locale).utc();

				// Re-calculate the duration of the first campaign and the sending of the emails basing on the date selected 
				const CampaignDuration = this.$moment.duration(this.deadline.campaign.toUpperCase() || this.companyData.campaign_duration);
				const SendEmailsByDuration = this.$moment.duration(this.deadline.email.toUpperCase() || this.companyData.send_emails_by);

				const plannedEnd = this.$moment(plannedStart).add(CampaignDuration).local().locale(this.$i18n.locale).utc();
				const sendEmailsBy = this.$moment(plannedStart).add(SendEmailsByDuration).local().locale(this.$i18n.locale).utc();

				// Planned start date must be greater then the last running campaign end date
				// if (lastCampaignEnd && plannedStart.isBefore(lastCampaignEnd)) {
				// 	this.invalidField.push('plannedStartDate');
				// 	this.invalidField.push('plannedStartTime');
				// 	this.createAlert('danger', 'Campaigns.InvalidCampaignPlannedStart');
				// 	return;
				// }

				// Planned end date must be greater then the planned start date
				if (plannedEnd.isBefore(plannedStart)) {
					this.invalidField.push('campaignDuration');
					this.createAlert('danger', 'Campaigns.InvalidCampaignPlannedEnd');
					return;
				}

				// "Send emails by" date must be greater then the planned start date and lower than planned end date
				if (sendEmailsBy.isBefore(plannedStart) || sendEmailsBy.isAfter(plannedEnd)) {
					this.invalidField.push('sendEmailsBy');
					this.createAlert('danger', 'Campaigns.InvalidSendEmailsByDuration');
					return;
				}

				// Retrieve campaign ID
				const campaignId = this.tabs[0].campaign.campaign_id;

				// Format new Campaign dates
				const dates = {
					plannedStart: plannedStart.format('YYYY-MM-DD HH:mm:ss'),
					sendEmailsBy: sendEmailsBy.format('YYYY-MM-DD HH:mm:ss'),
					plannedEnd: plannedEnd.format('YYYY-MM-DD HH:mm:ss')
				};

				// Update Campaign dates
				campaignService.updateCampaignDates(campaignId, dates).then((res) => {
					console.debug("Campaign - updateCampaignDates success", res);
					this.createAlert('success', 'Campaigns.UpdatedCampaignDuration');

					this.tabs[0].campaign.planned_start = this.$moment(plannedStart);
					this.tabs[0].campaign.planned_end = this.$moment(plannedStart).add(CampaignDuration).locale(this.$i18n.locale).utc().format();
					this.tabs[0].campaign.send_emails_by = this.$moment(plannedStart).add(SendEmailsByDuration).locale(this.$i18n.locale).utc().format();

					this.editDuration = false;
				}).catch((error) => {
					console.error("Campaign - updateCampaignDates error", error);
					this.createAlert('danger', 'Campaigns.UpdatedCampaignDurationError');
				});
			}, 500);
		},
		resetCampaignDates() {
			const defaultPlannedStart = this.tabs[0].campaign.planned_start;

			// Creates complete dates by adding time values to selected date values
			const plannedStart = this.$moment(this.plannedStart.date + ' ' + this.plannedStart.time).local().locale(this.$i18n.locale).utc();

			this.$nextTick(() => {
				Vue.set(this.tabs[0].campaign, 'planned_start', plannedStart);
				Vue.set(this.tabs[0].campaign, 'planned_start', defaultPlannedStart);
				this.campaignDatesKey = this.campaignDatesKey +1;
			});
		},
		isSameDate() {
			// Check if same starting date
			const plannedStartDate = this.$moment(this.plannedStart.date + ' ' + this.plannedStart.time).utc().format('YYYY-MM-DD HH:mm');
			const defaultStartDate = this.$moment(this.tabs[0].campaign.planned_start).utc().format('YYYY-MM-DD HH:mm');

			let isSameStartDate = plannedStartDate === defaultStartDate;
			// Check if same duration
			const campaignDuration = this.$moment.duration(this.deadline.campaign.toUpperCase());

			this.start = {	
				date: this.$moment.utc(this.tabs[0].campaign.planned_start).format("YYYY-MM-DD"),
				time: this.$moment.utc(this.tabs[0].campaign.planned_start).format("HH:mm:ss") 
			} 

			this.end = {	
				date: this.$moment.utc(this.tabs[0].campaign.planned_end).format("YYYY-MM-DD"),
				time: this.$moment.utc(this.tabs[0].campaign.planned_end).format("HH:mm:ss") 
			} 

			const plannedEndDate = this.$moment(this.start.date + ' ' + this.start.time).add(campaignDuration).format('YYYY-MM-DD HH:mm');
			const defaultEndDate = this.$moment(this.end.date + ' ' + this.end.time).format('YYYY-MM-DD HH:mm');

			let isSameEndDate = plannedEndDate === defaultEndDate;

			// Check if same send emails duration
			const sendEmailsByDuration = this.$moment.duration(this.deadline.email.toUpperCase());
			const sendEmailsByDate = this.$moment(this.start.date + ' ' + this.start.time).add(sendEmailsByDuration).format('YYYY-MM-DD HH:mm');

			this.email = {	
				date: this.$moment.utc(this.tabs[0].campaign.send_emails_by).format("YYYY-MM-DD"),
				time: this.$moment.utc(this.tabs[0].campaign.send_emails_by).format("HH:mm:ss") 
			} 

			const defaultSendEmailsByDate = this.$moment(this.email.date + ' ' + this.email.time).format('YYYY-MM-DD HH:mm');

			let isSameSendEmailsByDate = sendEmailsByDate == defaultSendEmailsByDate;

			if(!isSameStartDate || !isSameEndDate || !isSameSendEmailsByDate) {
				return false;
			} else {
				return true;
			}
		},
		toggleEditCampaignDates() {
			if(this.editDuration) {
				let dataLoss = this.isSameDate();
				if(dataLoss) {
					this.editDuration = !this.editDuration;
				} else {
					this.$bvModal.show('reset-modal-date');
				}	
			} else {
				this.editDuration = !this.editDuration;
			}
		},
		isValidDomain(email) {
			if(!email || !isEmail(email)) return 0;

			let trimmeredEmail = email.trim();

			// Check whether the email is contained into allowed account domain list
			return this.$checkPermission('list-company-all') || 
				((trimmeredEmail && this.emailDomain && this.emailDomain.length > 0) ? this.emailDomain.includes(trimmeredEmail.substring(trimmeredEmail.indexOf('@'))) : true);
		},
		async getCompletedCampaign() {
			let result = await companyService.getCampaignList(this.companyId, { status: Campaign.Status.Archived });
			let formattedCampaigns = result.data.map(campaign => ({
				...campaign,
				planned_start: this.$moment(campaign.planned_start).format("YYYY-MM-DD HH:mm:ss"),
				planned_end: this.$moment(campaign.planned_end).format("YYYY-MM-DD HH:mm:ss")
			}));

			return formattedCampaigns;
		}
	},
	async created() {
		this.inProgress = true;

		const config = await syspropService.getConfig();
		
		let companyRes = await companyService.getCompany(this.companyId);

		if(companyRes.data.email_domain) {
			this.emailDomain = companyRes.data.email_domain.split(/,| /).filter( dom => { return  dom!=''} );
			this.validDomain = {
				count: this.emailDomain.length,
				domain: this.emailDomain.join(', ')
			}
		}

		let templ_spec_rate = companyRes.data.template_specific_assign_rate;

		this.companyData = {
			company: companyRes.data,
			contact: config.data.defaults.contact,
			cgsmtp: config.data.defaults.cgsmtp,
			campaign_duration: config.data.defaults.campaign.duration,
			send_emails_by: config.data.defaults.campaign.sendEmailPhaseDuration,
			img_url: config.data.defaults.campaign.img_url || `{{.BaseURL}}`,
			gp_landing_url: config.data.defaults.gp_landing_url,
			sms_enabled: !!companyRes.data.sms_enabled || false,
			n_onboarding_campaigns: config.data.defaults.campaign.onboardingPhaseLength,
			template_specific_assign_rate: (typeof templ_spec_rate == 'number' && templ_spec_rate >= 0) ? templ_spec_rate : config.data.defaults.campaign.templateSpecificAssignRate
		}
		
		this.minTemplateAllowed = companyRes.data.n_min_campaign_templates || config.data.defaults.campaign.minimumTemplates;

		// Check if Onboarding is completed
		companyService.getCampaignList(this.companyId, null, Campaign.Type.Ordinary).then((result) => {
			let onboarding_campaigns = companyRes.data.n_onboarding_campaigns || this.companyData.n_onboarding_campaigns;
			if(result.data.length > 1 && result.data.length <= onboarding_campaigns){
				this.isOnboardingCompleted = false; 
			}
		}).catch((error) => {
			console.error("Campaign - getCampaignList error", error);
		});

		this.getAllCampaign();
		this.templateStatusOption = [
				{ value: null, text: this.$i18n.t('Campaigns.AllTemplates') },
				{ value: '1', text: this.$i18n.t('Campaigns.Included') },
				{ value: '0', text: this.$i18n.t('Campaigns.Excluded') }
			];
		this.previewLanguage = this.$i18n.locale || "en";
		this.preview = null;

		this.getCompletedCampaign();

		this.campaignFields = [
			{ key: "name", label: this.$t('General.Name')},
			{ key: "planned_start", label: this.$t('Campaigns.PlannedStart') },
			{ key: "planned_end", label: this.$t('Campaigns.PlannedEnd') },
			{ key: "actions", label: this.$t('General.Actions'), class: 'text-center' }
		];

		this.supportMessage = this.companyData.contact? this.$t('Campaigns.ContactUs', { param: this.companyData.contact }) : this.$t('Campaigns.ContactSupport');

		if (this.$checkPermission('approve-campaign')) {
			campaignService.readyToApprove(this.companyId).then(() => {
				console.debug("Campaign - readyToApprove success");
				this.canApprove = true;
			}).catch(err => {
				console.error("Campaign - readyToApprove error", err);
				this.canApprove = false;
				this.alertVariant = 'danger';
				this.alertMessage = 'Campaigns.' + err.response.data.error;
				this.$refs.cgLocAlert.showAlert();
			});
		}

		// Test Campaign management
		let result = await companyService.getCompanyTestTargets(this.companyId, { campaignType: Campaign.Type.Test });
		this.testTargetList = result && result.data? result.data : [];

		// Get all languages that appear in template content
		var templateLanguages = await companyService.getTemplateLanguages(this.companyId);
		var landingPageLanguages = await companyService.getLandingPageLanguages(this.companyId);

		// Get all system languages
		var appLanguages = await syspropService.getLanguages();

		// Extract from the language arrays the ISO code
		var tl = templateLanguages.data.map(lan => lan.language_id);
		var lpl = landingPageLanguages.data.map(lan => lan.language_id);
		var al = appLanguages.data.map(lan => lan.language_id);

		// Merge the result to obtain the target available language dropdown list
		var usedLanguages = [...new Set(tl.concat(al).concat(lpl))];
		
		// Create the object in the form (key, value) with key the ISO code and value the translation according to user's locale
		var obj = {};
		usedLanguages.forEach((lan) => {
			if(this.$langs[lan]) {
				obj[lan] = this.$langs[lan];
			}
		});

		Vue.set(this.fields.find(i => i.id === 'language'), 'options', obj);
		Vue.set(this.fields.find(i => i.id === 'email'), 'validator', this.isValidDomain);
		Vue.set(this.fields.find(i => i.id === 'telephone'), 'validator', this.isValidTelephoneNumber);

		this.TEMPLATE_TYPE_SMS = TemplateType.SMS;
		this.TEMPLATE_TYPE_EMAIL = TemplateType.Email;

		this.testTargetFields = [
			{ key: "firstname", label: this.$t('Targets.FirstName'), sortable: true },
			{ key: "lastname", label: this.$t('Targets.LastName'), sortable: true },
			{ key: "email", label: this.$t('Targets.Email'), sortable: true },
			{ key: "language", label: this.$t('Targets.Language'), sortable: true },
			{ key: "telephone", label: this.$t('Targets.Telephone'), sortable: true },
			{ key: "actions", label: this.$t('Targets.Actions'), sortable: false }
		];
		
		if(!this.companyData.sms_enabled) {
			Vue.set(this.fields.find(i => i.id === 'telephone'), 'display', false);
			Vue.set(this.testTargetFields.find(i => i.key === 'telephone'), 'class', 'd-none');
		}

		// Retrieve all countries for targets and templates
		let targetCountryList = await companyService.getTargetCountries(this.companyId);
        let isoCountries = await this.$isoCountriesLanguages.getCountries(this.$i18n.locale);
 
		this.tabs[0].campaignTemplates.forEach((elem) => { elem.label = elem.country && elem.country.split('#'); });

		// Create option list with country key translations
		targetCountryList.data.forEach((c) => {
			if(c.country) {
				this.templateCountryOptions.push({ text: isoCountries[c.country], value: c.country });
			}
		});
		this.templateCountryOptions.sort((a, b) => { return ('' + a.text).localeCompare('' + b.text); });
		this.templateCountryOptions.unshift({ text: this.$t('Template.AllCountries'), value: null });

		this.inProgress = false;
	}
};
</script>

<style lang="less">
#campaigns {
	.timeline {
        width: 100px;
		&.center {
			width: 160px;
        }
        &.right {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
    .day {
		&.first {
			left: 90px;
		}
		&.last {
			left: 250px;
		}
	}
	.date-widget {
		width: 360px;
	}
	.campaign-summary {
		width: max-content;
	}
	.pencil-edit {
		filter: drop-shadow(0px 0px 3px #22325a);
	}
	.campaign-date {
		text-align: center;
		border: none;
		background-color: #ffffff;
		label {
			font-weight: 700;
		}
		button {
			display: none;
		}
	}
	.campaign-date-static {
		width: 50%; 
		display: inline-block;
		text-align: center;
	}
	.campaign-date-edit {
		text-align: left;
		border: 1px solid #ced4da;
		button {
			display: block;
		}
	}
	.campaign-time {
		label {
			font-weight: 700;
		}
	}
	.icon-append.search  {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}
	.left-border-radius {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}
	.border-top {
		border-top: @cg-border-light;
	}
	.underlined-button {
		text-decoration: underline;
		color: @cg-dark-gray-text-color;
		&:hover {
			color: @cg-dark-gray-text-color;
		}
	}
	.selected-template {
		font-size: 14px;
		&.counter {
			text-align: end;
		}
	}
}
.template-grid {
	width:10rem;
}

.campaign-action-container {
	border: @cg-border-light;
	border-radius: 4px;
	padding: 8px 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	min-height: 100px;
}
.campaign-action-title {
	position: absolute;
	top: -13px;
	background: white;
	border-radius: 4px;
	padding: 0 8px;
	font-size: 14px;
}

// Small devices (landscape phones, up to 576px)
@media only screen and (max-width: 575px) {
	.margin-bottom-vertical-sm {
		margin-bottom: 32px;
	}
}
// Medium devices (tablets, up to 768px)
@media only screen and (max-width: 767px) {
	.margin-bottom-vertical-md {
		margin-bottom: 24px;
	}
}
</style>